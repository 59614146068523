import Logo from './logoOrange.png';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Routes, Route, Switch, Link} from 'react-router-dom';
import Login from './modules/login';
import Logout from './layouts/logout';
import Home from './layouts/home';
import China from './layouts/china';
import HKG from './layouts/hk';
import USA from './layouts/us';
import UnderDev from './layouts/under_dev';
import HotEtfTable from './modules/performance/HotEtfTable';
import IndexEtfTable from './modules/performance/IndexEtfTable';
import IndustryEtfTable from './modules/performance/IndustryEtfTable';
import ConnectedIndexEtfApp from './modules/performance/ConnectedIndexTable';
import ConceptEtfTable from './modules/performance/ConceptEtfTable';
import CountryEtfTable from './modules/performance/CountryEtfTable';
import DashboardLayout from './layouts/dashboard';
import {Provider} from 'react-redux';


export default function AppV6() {

  return (
      <Routes >
        <Route path='/' element={<DashboardLayout/>} >
          <Route index  element={<China/>}/>
          <Route  path='best'  element={<China isGood={1}/>}/>
          <Route path='worse'  element={<China isGood={0}/>}/>
          <Route path='hot' element={<HotEtfTable isGood="1" rowCount="30"/>} />
          <Route path='index'  element={<ConnectedIndexEtfApp/>}/>
          <Route path='industry'  element={<IndustryEtfTable rowCount={30}/>}/>
          <Route path='concept'  element={<ConceptEtfTable rowCount={30}/>}/>
          <Route path='country'  element={<CountryEtfTable rowCount={30}/>}/>
          {/* <Route path='cn'  element={<UnderDev/>}/>
          <Route path='hk'  element={<UnderDev/>}/>
          <Route path='us'  element={<UnderDev/>}/> */}
        </Route>
        <Route path='/login' element={<Login/>} />
        <Route path='/logout' element={<Logout/>} />

      </Routes>

  )
}