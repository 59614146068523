import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Save, Refresh,  List , ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import { ButtonGroup,  AppBar, Toolbar, Divider, IconButton, CircularProgress,Snackbar, Alert, AlertTitle, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import EtfPerformanceTabs from './CnEtfPerformanceTable';


function RecentPerformance({isGood, rowCount}) {

  function loadMenuData(idx){
    console.log("loadMenuData for: " + idx);
  }

  return (
    
          <Stack direction="column"  sx={{ flexGrow: 1 }}>
              <EtfPerformanceTabs isGood={isGood} rowCount={rowCount}/>
          </Stack>
    
     
  );
}

export default RecentPerformance;
