
//https://cn.redux.js.org/api/createstore/

import {createSlice} from '@reduxjs/toolkit';

import {SHOW_ACTION, CLOSE_ACTION} from './constants';


const counterSlice = createSlice({
    name: 'counter',
    initialState:false,
    reducers:{
        open: (state,)=>{
            // console.log("opened.");
            return true;
        },
        close: (state,)=>{
            // console.log("closed.");
            return false;
        },
        other: (state, action)=>{
            console.log(state, action);
            return state;
        }
    }

})
// export const selectCount = (state: RootState)=>state.counter.value;


export const {open, close, other} = counterSlice.actions;
export  const reducers =  counterSlice.reducer;   