import { Margin } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MyApi from '../globalAPI';
import {renderFundCode, renderHeader,  renderRatio} from './datagrid_helper';

function ResultTable({selected, data}) {

    const renderHeader2 = (props) =>{
        return renderHeader( props, selected);
    }

    const columns = [
    { field: 'fundCode', headerName: 'ETF代码', width: 100, renderCell: renderFundCode },    
    { field: 'fundName', headerName: 'ETF名称', width: 130 },
    { field: 'last_1w',  renderHeader: renderHeader2,  width: 100, type: "number"  , renderCell: renderRatio },
    { field: 'last_1m',   renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_3m', renderHeader: renderHeader2,  width: 100 , type: "number", renderCell: renderRatio },
    { field: 'last_6m', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'soy', renderHeader: renderHeader2,     width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_1y', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_3y', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    // { field: 'since_start', headerName: '成立以来', width: 100 , type: "number",  valueFormatter:percentFormatter },
    { field: 'nav', headerName: '最新净值', width: 100 , type: "number"},
    { field: 'navDate', headerName: '净值日期', width: 100 },
    { field: 'startDate', headerName: '成立日期', width: 100 },
    ];

    return (
        <DataGrid autoHeight 
            disableColumnMenu={true}
            rows={data}
            density="compact"
            columns={columns}
            // pagination={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            // pageSize={20}
            // rowsPerPageOptions={[20]}
            
            />
    )
}


export default function EtfPerformanceTabs({isGood, rowCount}) {

    const displayName=isGood?"最佳":"最差";
    const tabPages=[
        {code:'last_1w', name:"一周"+displayName},{code:'last_1m', name:"一月"+displayName},
        {code:'last_3m', name:"三月"+displayName},{code:'last_6m', name:"半年"+displayName},
        {code:'soy', name:"今年"+displayName}, {code:'last_1y', name:"一年"+displayName}, 
        {code:'last_3y', name:"三年"+displayName}
    ]


    const [value, setValue] = React.useState("last_1w");
    const [selectedVal, setSelectedVal] =useState('last_1w');
    const [isLoading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const initData= []
    const [dataRows, setDataRows] =useState(initData);
    

    const handleChange = (event, newValue) => {
        console.log("Tabs' newValue:", newValue);
        setLoading(true);
        setValue(newValue);
    };
    
    const loadData = (val) =>{
        // const val=pages[index];
        setLoading(true);
        setSelectedVal(val);
        
        console.log("loadData for: " + val);
    }


    
  useEffect(()=>{
    const api = new MyApi();
    setLoading(true);
    
    api.getETFTopPerformer(selectedVal, isGood, rowCount, 
    (response)=> {
      console.log("Loading success:",response.status);
    //   console.log(response.status);
      setDataRows(response.data.data);
      setLoading(false);
   }, (error)=>{console.log("Loading error!" , error); 
    setLoading(false); setHasError(true);});
     
   console.log("Request is sent...");
    } ,[selectedVal, isGood]);


    return (
        <Box sx={{ width: '100%', alignContent:'center' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} alignItems="center" alignContent="center">
            <Tabs value={value} onChange={handleChange} alignItems="center" 
            alignContent="center"
            aria-label="basic tabs example">
            {
                tabPages.map((page, index)=>(
                <Tab label={page.name} value={page.code}  onClick={(val)=>{loadData(page.code)}} />
            ))}
           
            </Tabs>
             
        </Box>
               
        {isLoading && 
              <Container maxWidth={true} align="center" style={{marginTop:200}}>
              <CircularProgress />
              </Container>
        }
         {!isLoading && 
          <ResultTable selected={selectedVal} data={dataRows}/>
         }
        </Box>
    );
}
