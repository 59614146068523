import {createSlice, configureStore} from  '@reduxjs/toolkit'
import {reducers} from './calc_slice';
import {person_reducers } from './person_slice';

const storev2 = configureStore({
    reducer: {'counter': reducers, 'person': person_reducers},
    // devTools: __DEV__,
    // middleware: getde
    
});

export default storev2;