
//https://cn.redux.js.org/api/createstore/

import {createSlice} from '@reduxjs/toolkit';


// export interface PersonState{
//     name:String,
//     age: Number,
//     address: String
// }
const personSlice = createSlice({
    name: 'person',
    initialState:{name:"rock", age:1, address:"China"},
    reducers:{
        update_name: (state, action)=>{
            console.log(state, action);
            state.name=action.payload;
            return state;
        },
        update_age: (state, action)=>{
            console.log(state, action);
            state.age=action.payload;
            return state;
        },
        update_address: (state, action)=>{
            console.log(state);
            state.address=action.payload;
            return state;
        }
    }

})
// export const selectCount = (state: RootState)=>state.counter.value;


export const {update_name, update_age, update_address} = personSlice.actions;
export  const person_reducers =  personSlice.reducer;   