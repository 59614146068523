
//https://cn.redux.js.org/api/createstore/

import {createSlice} from '@reduxjs/toolkit';

import {ADD_ACTION, MINS_ACTION} from './constants';


const counterSlice = createSlice({
    name: 'counter',
    initialState:1,
    reducers:{
        add: (state,)=>{
            console.log(state);
            state=state+1;
            return state;
        },
        mins: (state,)=>{
            console.log(state);
            state=state-1;
            return state;
        },
        other: (state, action)=>{
            console.log(state, action);
            state=state+action.payload*1;
            return state;
        }
    }

})
// export const selectCount = (state: RootState)=>state.counter.value;


export const {add, mins, other} = counterSlice.actions;
export  const reducers =  counterSlice.reducer;   