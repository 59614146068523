import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Save, Refresh,  List , ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import { ButtonGroup,  AppBar, Toolbar, Divider, IconButton, CircularProgress,Snackbar, Alert, AlertTitle, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; 
import Card from '@mui/material/Card';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import RecentPerformance from './performance/RecentPerformance';
import HotEtfTable from './performance/HotEtfTable';

function ChinaETF({isGood=1}) {
    const [selectedMenu, setSelectedMenu]=useState(0);

    function loadMenuData(idx){
    console.log("loadMenuData for: " + idx);
    setSelectedMenu(idx);
    }


    const menus=['表现最佳','表现最差','热门ETF','指数ETF', '行业ETF', '主题ETF', '商品ETF', '做空ETF'];


    return (
        <Container maxWidth={true} direction="column" disableGutters={false} alignItems="left" > 
        
      
            <RecentPerformance isGood={isGood} rowCount="30"/>
     
        </Container>
    );
}

export default ChinaETF;
