import {createSlice, configureStore} from  '@reduxjs/toolkit'
import {reducers} from './showMoreReducerSlice';
// import {person_reducers } from './person_slice';

const showMoreStoreV2 = configureStore({
    reducer: {'counter': reducers},
    // devTools: __DEV__,
    // middleware: getde
    
});

export default showMoreStoreV2;