
import React, { useState, useEffect } from 'react';
import { Link, Typography, Tooltip, Box, Stack, CircularProgress, IconButton } from '@mui/material';
import getPeriodName from '../constants';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MyApi from '../globalAPI';
// import showMoreStore from './showMoreStore';
// import {SHOW_ACTION} from './constants';
import showMoreStoreV2 from './showMoreStoreV2';
// import {useDispatch, useSelector, Provider} from 'react-redux';
import {open, close} from './showMoreReducerSlice'

const percentFormatter = (params) => {
    if (params.value == null) {
      return '';
    }

    const valueFormatted = Number(params.value).toLocaleString();
    return `${valueFormatted}%`;
  }
  
const renderRatio = (props) =>{
    // console.log('Render renderRatio:', props);
    const {value} = props;
    return (
        <Typography color={(value>0)?'red' :'green'} fontSize="14px">
            {percentFormatter(props)}
        </Typography>
    )
}

const renderFundCode = (props) =>{
    const {value} = props;
    const url = "http://fund.eastmoney.com/"+String(value)+".html"
    return (
        <Stack direction={"row"} alignItems={"center"}>
        <Typography color={(value>0)?'red' :'green'} fontSize="14px">
           <Link href={url}  target="blank">{value}</Link>
        </Typography>
        </Stack>
    )
}

const renderHeader = ( props, selected) =>{
    // console.log('Render header:', selected)
    const {field} = props;
    const fieldName= getPeriodName(field);
    const fontWeight = selected===field?"bold": "";
    const fontSize = selected===field?"14px": "14px";
    const color = selected===field?"blank": "blank";
    const backgroundColor = "whitle"; //selected===field?"#25e47a": "whitle";

    return  (
    <Typography  fontSize={fontSize} sx={{fontWeight: fontWeight, color: color}} >
      {fieldName}

    </Typography>
    )
    
}

const addToFavorite = (fundCode) =>{
    console.log("addToFavorite:"+ fundCode);
    const api = new MyApi();
    api.addMyETFFavorite(fundCode , 
        (resp)=>{console.log("Response:" , resp)}, 
        (err)=>{console.log("Error:", err)})
}



const showMore = (fundCode) =>{
    console.log("showMore:"+ fundCode);
    // const action={type:SHOW_ACTION, data:true};
    // showMoreStore.dispatch(action);

    showMoreStoreV2.dispatch(open())
    // const dispatch = useDispatch();
    // dispatch(open());

}

const renderActions =(props)=>{
    const {row}=props;
    // console.log('row=' + row);
    return (
       
        <Box>
            <Tooltip title="加收藏">
                <IconButton size='small'  onClick={(e)=>{addToFavorite(row.fundCode)}}>
                    <FavoriteBorderIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="更多统计">
                <IconButton size='small' title='' onClick={(e)=>{showMore(row.fundCode)}}>
                    <ShowChartIcon/>
                </IconButton>
                {/* <ShowMoreDialogs initOpen="false"/> */}
            </Tooltip>
            {/* <Typography >
      {row.fundCode}

    </Typography> */}
       </Box>
    )
}

export {renderFundCode, renderHeader, renderActions, renderRatio}
