import {connect} from 'react-redux';
import SimplePersonReduxV2 from './simple_person_redux_v2';
import SimpleCalcReduxV2 from './simple_calc_redux_v2';
import storev2 from './calc_store_v2';


function connectedReduxApp(){

        
    return (

      
        <div>
            
            <SimplePersonReduxV2 store={storev2}/>
            <SimpleCalcReduxV2 />
        </div>
    )
}

const mapState = (state) => {
    console.log('mapState.state:', state)
    return state;
};

const ConnectedApp = connect(mapState)(connectedReduxApp)
export default ConnectedApp;  