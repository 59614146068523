import Logo from '../logoOrange.png';
import '../App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {AppBar, Toolbar, Divider, IconButton, CircularProgress,Snackbar, Alert, AlertTitle, Stack } from '@mui/material';
import {useNavigate} from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import {Outlet, Route, Switch, Link} from 'react-router-dom';
import { margin } from '@mui/system';
function SiteLogo(){
  return (
    <img src={Logo} alt="etfonline.cn" width="200px"  />
  )
}

export default function DashboardLayout() {

  const jumpTo = ()=> {
    const w = window.open('_black') //这里是打开新窗口
    let url = 'https://beian.miit.gov.cn/'
    w.location.href = url //这样就可以跳转了
    }

  const pages= [
    // {code:'home', name:"首页", link:"/"} , 
    {code:'best', name:"涨幅排名", link:"/best"} , 
    {code:'worse', name:"跌幅排名", link:"/worse"} , 
    // {code:'hot', name:"热门ETF", link:"/hot"} , 
    {code:'index', name:"指数ETF", link:"/index"} , 
    {code:'industry', name:"行业ETF", link:"/industry"} , 
    {code:'concept', name:"主题ETF", link:"/concept"} , 
    {code:'concept', name:"国家ETF", link:"/country"} , 
    // {code:'cn_etf', name:"沪深ETF", link:"/cn"},
    // {code:'hk_etf', name:"港股ETF", link:"/hk"},
    // {code:'us_etf', name:"美股ETF", link:"/us"},
  ]

  const navigator=useNavigate();
  const linkto=(path)=>{
      console.log("Menu clicked." , path);
      navigator(path);
  }

  return (
    <Box sx={{ flexGrow: 1 }} >
      <Box>
        <AppBar  position='static' color='primary'  >
          <Toolbar alignItems="center" align="center"  anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} >
            <Stack direction="row" alignContent="center" style={{border:1}} spacing={5}>
              <IconButton img={Logo} color="inherit" size="large" edge="start" sx={{ mr: 2 }}>
              <SiteLogo  />
              </IconButton>
            
              {/* <Typography variant='h6'  sx={{ flexGrow: 1 }}>
                最新ETF排名
              </Typography>
               */}
              {
                pages.map((page, index)=>( 
                  <MenuItem key={index} size="small" align="center" onClick={(val)=>{linkto(page.link)}} >
                    {/* <Link to={page.link}> */}
                    <Typography color="default" key={index}  variant='body'  align='center'>
                    {page.name} 
                    </Typography>
                    {/* </Link> */}
                    </MenuItem>
                                  
                  
                ))
              }
         
              </Stack>
          </Toolbar>
        </AppBar>
        </Box>
        <Outlet/>
        <Box sx={{ flexGrow: 1, margin:"23px", border:"2px" }}>
        <Typography variant='h6' >重要声明：</Typography>
        <hr/>
        <Typography variant='h7'>
        1. 所有数据均来自于互联网，每一页只最多展示最近30条数据，每个交易日10点晚上更新净值和排名数据。
        <br/>
        2. 网站数据仅供个人学习研究，不得用于商业用途，不构成任何投资建议和参考。
        <br/> 
        3. 基金净值数据可能有延时或错误，本网站不负任何责任。
        </Typography>
        <Typography variant='h6' align='center'  >
        <hr/>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备2022072667号-1</a>
        </Typography>
        </Box>
        
      </Box>
  );
}

