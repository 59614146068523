import {connect} from 'react-redux';
import IndexEtfTable from './IndexEtfTable';
import showMoreStoreV2 from './showMoreStoreV2';

function connectedIndexTable(){
        
    return (

      
        <div>
            
            <IndexEtfTable store={showMoreStoreV2}  rowCount={30} />
        
        </div>
    )
}

const mapState = (state) => {
    console.log('mapState.state:', state)
    return state;
};

const ConnectedIndexEtfApp = connect(mapState)(connectedIndexTable)
export default ConnectedIndexEtfApp;  