import {useDispatch, useSelector, Provider} from 'react-redux';

import { Component } from 'react';
import {add, mins, other} from './calc_slice';
import {update_address, update_age, update_name} from './person_slice';

import storev2 from './calc_store_v2';

export default function SimpleCalcReduxV2 (){
    
    const dispatch = useDispatch();
    // const state= storev2.getState();  //useSelector(selectCount);
    // console.log('state:',storev2.getState())
        return (    
           
          
           <div>
            <h1>Simiple Calculator with Redux Toolkit</h1>
            <br/>
            当前计算结果{storev2.getState().counter}
            <br/>
            <select onChange={()=>{dispatch(other(111))}} >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
            </select>
            <button  onClick={()=>{dispatch(add())}}>加</button>
            <button  onClick={()=>{dispatch(mins())}}>减</button>
            </div>     
           

        )
    
}