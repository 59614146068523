
const periods= [
{ field: 'last_1d',  headerName: '当日涨幅' },
{ field: 'last_1w',  headerName: '最近一周' },
{ field: 'last_1m', headerName: '最近一月'},
{ field: 'last_3m', headerName: '最近三月'},
{ field: 'last_6m', headerName: '最近半年'},
{ field: 'soy', headerName: '今年以来' },
{ field: 'last_1y', headerName: '最近一年' },
{ field: 'last_3y', headerName: '最近三年' },
{ field: 'since_start', headerName: '成立以来' },
];

export default function getPeriodName(code){
    let name='Unknown'
    periods.forEach( p=> {
        // console.log(p);
        if (p.field === code){name = p.headerName; return name;}
    })

    return name;
    
}