import axios from 'axios';
import Qs from 'qs';

// const baseURL="api/"; 
const baseURL="http://124.220.107.93:9999/"
const baseHeaders={}; 
// {'Content-Type':'application/json;charset=UTF-8','Content-Type':'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin':'*'};
const baseTimeout=10000;

axios.defaults.baseURL=baseURL;
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
class MyApi {
    constructor(){
        console.log("Creating class instance...");
        // this.baseURL = baseURL;
        // this.instace=axios.create();
        // this.instace.defaults.headers.get['Content-Type']=;
        // this.instace.defaults.headers.post['Content-Type']='application/json;charset=UTF-8';
    }

    static init() {
        const newInstance =axios.create(
            {
                baseUrl: baseURL,
                timeout: baseTimeout,
                headers: baseHeaders
            }
        );

        return newInstance;
    }

    getData (url, onSucess, onError) {
        // const headers=this.baseHeader;
        const config={"url": url, "baseURL": this.baseURL, "method": "get", "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);


    }


    postData (url, data, onSucess, onError) {
        const config={"url": url , "baseURL": this.baseURL, 
            "data": data, "method": "post" , "responseType":"json", "headers":baseHeaders}
        console.log(Qs.stringify(data));
            return  MyApi.init().post(url ,data, config).then(onSucess).catch(onError);
    }

    requestData(url, method, data, onSucess, onError) {
       
        const config={"url": url, "baseURL": this.baseURL, 
            "data": Qs.stringify(data), "method":method, "responseType":"json", "headers":baseHeaders}
        return  MyApi.init().request(config, Qs.stringify(data)).then(onSucess).catch(onError);
    }

    getStockBasic(code, onSucess, onError) {
        const url=`stock/basic/${code}`;
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getMyETFList(onSucess, onError) {
        const url=`etf/my/all`;
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    addMyETFFavorite(code, onSucess, onError) {
        const url=`etf/my/add`;
        const data={'code': code}
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().post(url, data, config).then(onSucess).catch(onError);
    }

    getETFTopPerformer(period, isGood, limit, onSucess, onError) {
        const url=`etf/performance/${period}?limit=${limit}&isGood=${isGood}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getHotIndexEtf(limit, onSucess, onError) {
        const url=`etf/hot/index?limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    
    getIndustryName( limit, onSucess, onError) {
        const url=`etf/performance/top/industry?limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }
    
    getIndustryEtf(industryName, limit, onSucess, onError) {
        const url=`etf/index/industry?name=${industryName}&limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getConceptName( limit, onSucess, onError) {
        const url=`etf/performance/top/concept?limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }
    
    getConceptEtf(industryName, limit, onSucess, onError) {
        const url=`etf/index/concept?name=${industryName}&limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getCountryName( limit, onSucess, onError) {
        const url=`etf/performance/top/country?limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getCountryEtf(countryName, limit, onSucess, onError) {
        const url=`etf/index/country?name=${countryName}&limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }


    getIndexEtf(indexName, limit, onSucess, onError) {
        const url=`etf/index/index?name=${indexName}&limit=${limit}`;
        console.log("Request url:", url);
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }
    

    getETFBasic(code, onSucess, onError) {
        const url=`etf/basic/${code}`;
        const config={"url": url, "baseURL": this.baseURL, "responseType":"json"}
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }

    getETFByBenchmark(benchmark, onSucess, onError) {
        const url=`etf/basic/benchmark`;
        const params={"value": benchmark};
        const config={"url": url, "params" :params, "baseURL": this.baseURL, "responseType":"json"}
        
        return MyApi.init().get(url, config).then(onSucess).catch(onError);
    }
}

export default MyApi;