import * as React from 'react';
import  { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import showMoreStoreV2 from './showMoreStoreV2';
import {open, close} from './showMoreReducerSlice'
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReorderIcon from '@mui/icons-material/Reorder';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BasicTable({rows}) {
    return (
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><KeyboardArrowRightIcon/></TableCell>
              <TableCell align="right">周期</TableCell>
              <TableCell align="right">涨跌幅%</TableCell>
              <TableCell align="right">同类平均</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <KeyboardArrowRightIcon/>
                </TableCell>
                <TableCell align="right">{row.period}</TableCell>
                <TableCell align="right">{row.performance}</TableCell>
                <TableCell align="right">{row.avg}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ShowMoreCustomizedDialogs(props) {

    const {isOpen, data} = props;
    console.log("Isopen=", isOpen)

    
  const [open, setOpen] = React.useState(isOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    showMoreStoreV2.dispatch(close())
  };

//   setOpen(isOpen);

useEffect(()=>{setOpen(isOpen)},[isOpen]);

  return (
    <div >
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
         {data.fund_name} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
              {/* <Typography >
           基金代码： {data.fund_code}
             </Typography>
             <Divider variant="middle" />
             <Typography >
           基金名称： {data.fund_name}
             </Typography> */}
        <BasicTable rows={data.rows}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            关闭
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}