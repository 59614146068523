import { Label, Margin } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, CircularProgress } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MyApi from '../globalAPI';
import ShowMoreDialogs from './showMoreDialogBox';
import {SHOW_ACTION} from './constants';
// import showMoreStoreV2 from './showMoreStoreV2';
import {useDispatch, useSelector, Provider} from 'react-redux';
import {open, close} from './showMoreReducerSlice'
import {renderFundCode,  renderHeader, renderActions,  renderRatio} from './datagrid_helper';

function IndexEtfResultTable({selected, data, store}) {
    // console.log('Store:')
    // console.log(store.getState());
    const renderHeader2 = (props) =>{
        return renderHeader( props, selected);
    }
        
    const columns = [
  
    { field: 'fundCode', headerName: 'ETF代码', width: 100, renderCell: renderFundCode },    
    { field: 'fundName', headerName: 'ETF名称', width: 130 },
    { field: 'last_1d',  renderHeader: renderHeader2,  width: 100, type: "number"  , renderCell: renderRatio },
    { field: 'last_1w',  renderHeader: renderHeader2,  width: 100, type: "number"  , renderCell: renderRatio },
    { field: 'last_1m',   renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_3m', renderHeader: renderHeader2,  width: 100 , type: "number", renderCell: renderRatio },
    { field: 'last_6m', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'soy', renderHeader: renderHeader2,     width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_1y', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    { field: 'last_3y', renderHeader: renderHeader2,  width: 100 , type: "number" , renderCell: renderRatio },
    // { field: 'since_start', headerName: '成立以来', width: 100 , type: "number",  valueFormatter:percentFormatter },
    { field: 'nav', headerName: '最新净值', width: 100 , type: "number"},
    // { field: 'navDate', headerName: '净值日期', width: 100 },
    // { field: 'startDate', headerName: '成立日期', width: 100 },
    { field: 'action', headerName: '操作', width: 100, renderCell: renderActions },    
   
    ];
    const fund_data={fund_name:"兴银国证新能源车电池ETF", fund_code:"159767", 
        rows:[{period:"一周", performance: "2.2%", avg:"2.1%"},
        {period:"两周", performance: "3.2%", avg:"4.1%"},
        {period:"一月", performance: "13.2%", avg:"9.1%"}
    ]}
    return (
        <Box>

        <DataGrid autoHeight 
            disableColumnMenu={true}
            rows={data}
            density="compact"
            columns={columns}
            // pagination={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            // pageSize={20}
            // rowsPerPageOptions={[20]}
            />

{/*             
        <Typography>
          Value:  {store.getState().counter.toString()}
        </Typography> */}

          <ShowMoreDialogs isOpen={store.getState().counter} data={fund_data} />

        </Box>
         
    )
}




export default function IndexEtfTable({ rowCount, store}) {

    const initIndex="上证50";
    const tabPages=[
        {code:'上证50', name:"上证50" },
        {code:'沪深300', name:"沪深300" },
        {code:'深证100', name:"深证100"},
        {code:'创业板', name:"创业板" },
        {code:'科创板', name:"科创板"},
        {code:'恒生科技', name:"恒生科技" },
        {code:'科创50', name:"科创50"},
        {code:'中证100', name:"中证100"},
        {code:'中证500', name:"中证500"},
        {code:'中证1000', name:"中证1000"},
    ]


    const [value, setValue] = React.useState(initIndex);
    const [selectedVal, setSelectedVal] =useState(initIndex);
    const [isLoading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const initData= []
    const [dataRows, setDataRows] =useState(initData);
    

    const handleChange = (event, newValue) => {
        console.log("Tabs' newValue:", newValue);
        setLoading(true);
        setValue(newValue);
    };
    
    const loadData = (val) =>{
        // const val=pages[index];
        setLoading(true);
        setSelectedVal(val);
        
        console.log("loadData for: " + val);
    }


    
  useEffect(()=>{
    const api = new MyApi();
    setLoading(true);
     
        console.log("Todo: ...", selectedVal);   
        api.getIndexEtf(selectedVal, rowCount, 
        (response)=> {
            console.log("Loading success:", response.data.data);
            console.log(response.status);
            setDataRows(response.data.data);
            setLoading(false);
        }, (error)=>{console.log("error!" , error); setLoading(false); setHasError(true);});
         
     console.log("Request is sent...");
    
    } ,[selectedVal]);
    

    return (
        <Container maxWidth={true} direction="column" disableGutters={false} alignItems="left">
        <Box sx={{ width: '100%', alignContent:'center' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} alignItems="center" alignContent="center">
            <Tabs value={value} onChange={handleChange} alignItems="center" 
            alignContent="center"
            aria-label="basic tabs example">
            {
                tabPages.map((page, index)=>(
                <Tab label={page.name} value={page.code}  onClick={(val)=>{loadData(page.code)}} />
            ))}
           
            </Tabs>
             
        </Box>
               
        {isLoading && 
              <Container maxWidth={true} align="center" style={{marginTop:200}}>
              <CircularProgress />
              </Container>
        }
         {!isLoading && 
          <IndexEtfResultTable data={dataRows} store={store}/>
          
         }

        </Box>
        </Container>
    );
}
