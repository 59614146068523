import {useDispatch} from 'react-redux';

import {update_address, update_age, update_name} from './person_slice';

// import storev2 from './calc_store_v2';

export default function SimplePersonReduxV2 (store){
    
    const dispatch = useDispatch();
    // const state= storev2.getState();  //useSelector(selectCount);
    const person=store.store.getState().person;
    console.log('person:',store);
    console.log('store:',store.store.getState().person)
        return (    
           
          
           <div>
            <h1>Simiple Person with Redux Toolkit</h1>
            <br/>
            当前Name: {person.name}
            <br/>
            当前Age: {person.age}
            <br/>
            当前Address: {person.address}
            <br/>
            Name: <input type="text" onChange={(event)=>{dispatch(update_name(event.target.value))}} />
              
            <button  onClick={()=>{dispatch(update_age(100))}}>Age</button>
            <button  onClick={()=>{dispatch(update_address("HK"))}}>Address</button>
            </div>     
           

        )
    
}